@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "BCASans";
  src: url("./assets/font/bcasans/BCASans-Extrabold.ttf") format("truetype");
}

.selected-button {
  border: 2px solid rgba(0, 0, 255, 0.5);
  box-shadow: 0 0 15px rgba(0, 0, 255, 0.5);
  animation: breathing 2s infinite;
}

@keyframes breathing {
  0% {
    box-shadow: 0 0 15px rgba(0, 0, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 30px rgba(0, 0, 255, 1);
  }
  100% {
    box-shadow: 0 0 15px rgba(0, 0, 255, 0.5);
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.transition-image {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.visible {
  opacity: 1;
}
